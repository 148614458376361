// src/i18n/config.ts

// Core i18next library.
import i18n from "i18next";                      
// Bindings for React: allow components to
// re-render when language changes.
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  // Add React bindings as a plugin.
  .use(initReactI18next)
  // Initialize the i18next instance.
  .init({
    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here. 
    fallbackLng: "en",

    // Enables useful output in the browser’s
    // dev console.
    debug: true,

    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn 
    // it off in i18next.
    interpolation: {
      escapeValue: false,
    },

    // Translation messages. Add any languages
    // you want here.
    resources: {
      // English
      en: {
        // `translation` is the default namespace.
        // More details about namespaces shortly.
        translation: {
            notification: {
                title: "MARC LABERGE",
                subtitle: "FROM PASSION TO INNOVATION"
            },
            events: {
              title_one:"Event",
              title_other:"Events"
            },
            socials: {
              title:"Socials"
            },
            projects: {
              title: "Projects",
              mnezy: {
                title: "MNEZY",
                subtitle: "Memory skill game",
                description: "",
                appStoreLink: "https://apps.apple.com/us/app/mnezy/id1513672558?itsct=apps_box_badge&amp;itscg=30200",
                websiteLink: "https://mnezy.marclaberge.org",
              },
              ssense: {
                title: "SSENSE",
                subtitle: "Luxury brands",
                description: "",
                appStoreLink: "https://apps.apple.com/us/app/ssense-shop-designer-fashion/id1418754101?itsct=apps_box_badge&amp;itscg=30200",
                websiteLink: "https://www.ssense.com/en-ca/",
              },
              desjardins: {
                title: "DESJARDINS",
                subtitle: "Finance",
                description: "",
                appStoreLink: "https://apps.apple.com/us/app/desjardins-mobile-services/id386636953?itsct=apps_box_badge&amp;itscg=30200",
                websiteLink: "https://www.desjardins.com/qc/en.html",
              }
            },
            education: {
                title: "Education",
                universityName: "Université de Sherbrooke",
                dates: "2016 - 2019",
                gradeName: "Bachelor of Computer Science",
            },
            work: {
                title: "Work experience",
                ssense3: {
                    roleName: "Mobile Technical Lead",
                    dates: "SINCE 2023",
                    subtitle: "SSENSE",
                },
                ssense2: {
                    roleName: "Mobile Software Developer III",
                    dates: "2021 - 2022",
                    subtitle: "SSENSE",
                },
                ssense1: {
                    roleName: "Mobile Software Developer II",
                    dates: "2020 - 2021",
                    subtitle: "SSENSE",
                },
                ssense0: {
                    roleName: "Mobile Software Developer",
                    dates: "2019",
                    subtitle: "SSENSE",
                },
                desjardins: {
                    roleName: "iOS Developer",
                    dates: "2018 - 2019",
                    subtitle: "Desjardins",
                }
            },
            languages: {
              title: "Languages",
            },
            frameworks: {
              title: "Frameworks",
            },
            platforms: {
              title: "Platforms",
            },
            SDKs: {
              title: "SDKs",
            },
            paymentMethods: {
              title: "Implemented Payment Methods",
            },
            others: {
              title: "Also worked with",
            },
            articles: {
              title: "Articles",
            },
            hobbies: {
              title: "Hobbies",
              list: [
                { title: "Walk" },
                { title: "Draw" },
                { title: "Conferences" },
                { title: "Travel" },
                { title: "Read" }
              ]
            },
            sports: {
              title: "Favorite sports teams",
              list: {
                montrealCanadiens: {
                  title: "Montreal Canadiens",
                  subtitle: "NHL"
                },
                lavalRocket: {
                  title: "Laval Rocket",
                  subtitle: "AHL"
                },
                bostonCeltics: {
                  title: "Boston Celtics",
                  subtitle: "NBA"
                },
                montrealFC: {
                  title: "Montreal FC",
                  subtitle: "MLS"
                },
                bostonRedSox: {
                  title: "Boston Red Sox",
                  subtitle: "MLB"
                }
              }
            }
        },
      },
      // French
      fr: {
        translation: {
          notification: {
              title: "MARC LABERGE",
              subtitle: "D'UNE PASSION À L'INNOVATION"
          },
          events: {
            title_one:"Événement",
            title_other:"Événements"
          },
          socials: {
            title:"Réseaux"
          },
          projects: {
            title: "Projets",
            mnezy: {
              title: "MNEZY",
              subtitle: "Un jeu de mémoire",
              description: "",
              appStoreLink: "https://apps.apple.com/ca-fr/app/mnezy/id1513672558?itsct=apps_box_badge&amp;itscg=30200",
              websiteLink: "https://mnezy.marclaberge.org",
            },
            ssense: {
              title: "SSENSE",
              subtitle: "Marques de luxe",
              description: "",
              appStoreLink: "https://apps.apple.com/ca-fr/app/ssense-shop-designer-fashion/id1418754101?itsct=apps_box_badge&amp;itscg=30200",
              websiteLink: "https://www.ssense.com/fr-ca/",
            },
            desjardins: {
              title: "DESJARDINS",
              subtitle: "Finances",
              description: "",
              appStoreLink: "https://apps.apple.com/ca-fr/app/desjardins-mobile-services/id386636953?itsct=apps_box_badge&amp;itscg=30200",
              websiteLink: "https://www.desjardins.com/qc/fr.html",
            }
          },
            education: {
                title: "Éducation",
                universityName: "Université de Sherbrooke",
                dates: "2016 - 2019",
                gradeName: "Baccalauréat en informatique",
            },
            work: {
                title: "Expériences de travail",
                ssense3: {
                    roleName: "Mobile Technical Lead",
                    dates: "DEPUIS 2023",
                    subtitle: "SSENSE",
                },
                ssense2: {
                    roleName: "Développeur Mobile III",
                    dates: "2021 - 2022",
                    subtitle: "SSENSE",
                },
                ssense1: {
                    roleName: "Développeur Mobile II",
                    dates: "2020 - 2021",
                    subtitle: "SSENSE",
                },
                ssense0: {
                    roleName: "Développeur Mobile",
                    dates: "2019",
                    subtitle: "SSENSE",
                },
                desjardins: {
                    roleName: "Développeur iOS",
                    dates: "2018 - 2019",
                    subtitle: "Desjardins",
                }
            },
            languages: {
              title: "Langages",
            },
            frameworks: {
              title: "\"Frameworks\"",
            },
            platforms: {
              title: "Platformes",
            },
            SDKs: {
              title: "Kits de développement (SDKs)",
            },
            paymentMethods: {
              title: "Méthodes de paiement implémentées",
            },
            others: {
              title: "A également travaillé avec",
            },
            articles: {
              title: "Articles",
            },
            hobbies: {
              title: "Loisirs",
              list: [
                { title: "Marche" },
                { title: "Dessin" },
                { title: "Conférences" },
                { title: "Voyages" },
                { title: "Lecture" }
              ]
            },
            sports: {
              title: "Équipes de sport préférées",
              list: [
                {
                  title: "Canadiens de Montréal",
                  subtitle: "LNH"
                },
                {
                  title: "Rocket de Laval",
                  subtitle: "LAH"
                },
                {
                  title: "Celtics de Boston",
                  subtitle: "NBA"
                },
                {
                  title: "CF Montréal",
                  subtitle: "MLS"
                },
                {
                  title: "Red Sox de Boston",
                  subtitle: "MLB"
                }
              ]
            }
        },
      },
    },
  });

export default i18n;